.ces-more-info {
	background: $color-backing-grey;
	color: $color-primary;
	font-size: $h6;
	line-height: 1.7em;
	padding: $block-padding;
	border-radius: $site-border-radius;
	border-right:  ($border-size + 1) $border-style $color-lightgrey;
	border-bottom: ($border-size + 1) $border-style $color-lightgrey;
	word-break: break-word;

	a {
		color: $color-highlight;

		&:hover {
			color: $color-secondary;
		}
	}
}
