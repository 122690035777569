
//==========  Borders  ==========//

// build our default border style 

$border-size: 1px;
$border-style: solid;
$border-color: $color-lightgrey;

// combine border variables into a default
// this is used throughout our modules as a base
$border: $border-size $border-style $border-color;

// set a default border-radius
// this is used in our forms and search styling
$border-radius: 4px;

// border radius for buttons to match design stylings
$button-border-radius: 10px;

// site border radius
$site-border-radius: 20px;
