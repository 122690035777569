
// Default link events
a {
	color: $color-secondary;
	text-decoration: none;
	transition: all t(link) ease-out;

	&:hover {
		color: $color-primary-light;
	}
}
