
// Content styles
.block-text,
.biography {
	line-height: 1.7;
	word-break: break-word;
	
	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			padding: $paragraph-margin 0;
			border-top: $border-style 4px $color-backing-grey;
			border-bottom: $border-style 4px $color-backing-grey;
			color: $color-sub;
			font-family: $font-family-sub;
			font-size: $h6 + 0.1;
			
			@media #{$mobileXL} {
				font-size: $h4;
			}
		}
	}

	// reset any <ul> that will appear in a template here
	// maintain vertical rhythm with paragraph margin
	ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;
	}
	
	// New default <ul> lists
	ul {
		@extend %default-ul;
	}
	
	// New default <ol> lists
	ol {
		@extend %default-ol;
	}
}


// news list style over-rides
.section-crossland_news .centre {
	
	.block {
	
		// Default paragraphs
		p {
			margin: 0 0 $paragraph-margin;

			&.intro {
				padding: $paragraph-margin 0;
				border-top: $border-style 4px $color-backing-grey;
				border-bottom: $border-style 4px $color-backing-grey;
				color: $color-sub;
				font-family: $font-family-sub;
				font-size: $h4;
				
				@media #{$mobileXL} {
					font-size: $h4;
				}
			}
		}

		// reset any <ul> that will appear in a template here
		// maintain vertical rhythm with paragraph margin
		ul[class] {
			@extend %reset-ul;
			margin: 0 0 $paragraph-margin;
		}
		
		// New default <ul> lists
		ul {
			@extend %default-ul;
		}
		
		// New default <ol> lists
		ol {
			@extend %default-ol;
		}

	}
}


// staff biography over-rides
.biography {

	p {

		&.intro {
			color: $color-primary;
		}
	}
}

