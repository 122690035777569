
//==========  Extends  ==========//

// use these classes and placeholders with @extends
// classes defined here will be output in the final compiled css
// placeholders will not and are only used within sass
 
// note - extends will not work within media queries


//==========  Helpers and resets  ==========//

// set on .inner containers
%full-width { 
	width: 100%;
	max-width: $max-width;
	margin: 0 auto;
}

// clear floats on elements after container
// prevents elements after from wrapping
%clear-fix {
	
	&:after {
		content: '';
		clear: both;
		display: block;
		width: 100%;
		overflow: hidden;
	}
}

// set child elements to be an automatic equal height
// must be set on parent container (not an ancestor!)
// %clear-fix should be removed (creates extra columns)
// flexbox progressive enhancement
%elastic {
	display: block;
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	overflow: hidden;
}

// svg icon reset - use to hide any icons
%no-icon {
	min-height: 0;

	.icon-link {
		min-height: 0;
		padding-left: 0;
	}

	.icon {
		display: none;
	}
}

// reset for images, prevents whitespace around image
%image {
	display: block;
	width: 100%;
}

// default style for blockquotes
%blockquote {
	margin: 0;
	
	// left quote marks
	&:before {
		content: '\201C';
	}
	
	// right quote marks
	&:after {
		content: '\201D';
	}
	
	// reset for p tags that are put in by the CMS editor
	p {
		display: inline;
		margin: 0;
	}
}

// add as class to element or extend
.hidden {
	display: none;
}

// loading animation styles
// set based styles for dots
// configure dot styles with mixin
%loading {
	@include centre(absolute);
	@include loading();

	p {
		margin: 0;
		text-align: center;
	}

	.dot {
		display: inline-block;
		transform: scale(0);
		border-radius: 50%;
		animation: loading 1s ease infinite;
		
		&:nth-child(2) {
			animation: loading 1s ease infinite 0.3s;
		}
		
		&:nth-child(3) {
			animation: loading 1s ease infinite 0.6s;
		}
	}
}

// assign the default to a global class
.loading {
	@extend %loading;
}


// create gradient for header and footer
%gradient {
	background: rgb(16,52,123); /* Old browsers */
	background: linear-gradient(to right, rgba(16,52,123,1) 0%,rgba(40,88,182,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#10347b', endColorstr='#2858b6',GradientType=1 ); /* IE6-9 */
}
