
//==========  Colors  ==========//

// set our colour variables
// avoid using obvious declarative names as the colours may not always match the name
// e.g, $colour-orange
// 
// we also create lighter and darker tones for use with hover states, etc.

$color-primary: 		#0d2046; 						// the brand colour
$color-primary-light:	lighten($color-primary, 5%);	// the brand colour - lighter
$color-primary-dark:	darken($color-primary, 5%);		// the brand colour - darker

$color-secondary: 		#de291e; 						// the accent colour
$color-secondary-light:	lighten($color-secondary, 5%);	// the accent colour - lighter
$color-secondary-dark:	darken($color-secondary, 5%);	// the accent colour - darker


$color-highlight:		#4a7dd2;						// highlight colour for buttons
$color-highlight-light:	lighten($color-highlight, 5%);	// the highlight colour - lighter
$color-highlight-dark:	darken($color-highlight, 5%);	// the highlight colour - darker


//==========  Service Buttons  ==========//

$color-service-one: 		#a9e34b; 								// first service colour
$color-service-one-dark:	darken($color-service-one, 15%);		// first service colour - darker

$color-service-two: 		#16d2a8; 								// second service colour
$color-service-two-dark:	darken($color-service-two, 15%);		// second service colour - darker

$color-service-three: 		#f47e0b; 								// third service colour
$color-service-three-dark:	darken($color-service-three, 15%);		// third service colour - darker

$color-service-four: 		#e8326d; 								// fourth service colour
$color-service-four-dark:	darken($color-service-four, 15%);		// fourth service colour - darker


//========== Staff cards ==========//
$color-staffcard:		#10357c; 							// the staff card colour
$color-staffcard-light:	lighten($color-staffcard, 5%);		// the staff card colour - lighter
$color-staffcard-dark:	darken($color-staffcard, 5%);		// the staff card colour - darker


//==========  Tones  ==========//

// set grey tones variables
// these will typically be used for borders and form inputs
// again, we also create lighter and darker tones for use with hover states, etc.

$color-lightgrey:		#eee;							// a default light grey
$color-lightgrey-light:	lighten($color-lightgrey, 5%);	// a default light grey - lighter
$color-lightgrey-dark:	darken($color-lightgrey, 5%);	// a default light grey - darker

$color-midgrey:			#bbb;	 						// a default mid grey
$color-midgrey-light:	lighten($color-midgrey, 5%);	// a default mid grey - lighter
$color-midgrey-dark:	darken($color-midgrey, 5%);		// a default mid grey - darker

$color-darkgrey:		#85898a;						// a default dark grey
$color-darkgrey-light:	lighten($color-darkgrey, 5%);	// a default dark grey - lighter
$color-darkgrey-dark:	darken($color-darkgrey, 5%);	// a default dark grey - darker

$color-white: 			#fff;							// a default white
$color-black: 			#111;							// a default black

$color-backing-grey:	#f9fbfb;						// a grey colour used for background colour on many elements

//==========  Text colors  ==========//

// set our default text colours for titles, paragraphs, etc.

$color-text:	#0d2046;								// body text colour
$color-sub:		#82929d;								// sub and intro text colour
$color-title:	$color-primary;							// heading text colour

