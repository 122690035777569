
//==========  List styles  ==========//

// list item styles
%default-list {
	padding: 0 0 0 $block-padding;
	margin: 0 0 $paragraph-margin;
}

%default-ul {
	list-style: disc;
	@extend %default-list;
}

%default-ol {
	list-style: decimal;
	@extend %default-list;
}

// list item reset
%reset-ul {
	list-style: none;
	padding: 0;
	margin: 0;
}


//==========  Buttons and default styles  ==========//

// base button styles
// extend for different button styles
%button {
	border: none;
	@include if( $border-radius, ( border-radius: $button-border-radius ) );
	background: $color-highlight;
	color: $color-white;
	font-size: $font-size-body - 0.3;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 3px;
	transition: $transition;
}

%hover {
	background: $color-secondary;
	color: $color-white;
}

// extend for default .read-more
%read-more {
	@extend %button;
	display: inline-block;
	padding: ($block-padding * 1/2) $block-padding;

	&:hover {
		@extend %hover;
	}
}


// extend for service button background - li > a backgrounds to tidy code up
%service-buttons {

	&:first-child {
		a {
			background: $color-service-one;

			&:hover {
				background: $color-service-one-dark;
			}
		}
	}

	&:nth-child(2) {
		a {
			background: $color-service-two;

			&:hover {
				background: $color-service-two-dark;
			}
		}
	}

	&:nth-child(3) {
		a {
			background: $color-service-three;

			&:hover {
				background: $color-service-three-dark;
			}
		}
	}

	&:nth-child(4) {
		a {
			background: $color-service-four;

			&:hover {
				background: $color-service-four-dark;
			}
		}
	}

}

//==========  Pager styles  ==========//

%bx-pager {

    .bx-pager-item {
        display: inline-block;
    }

    .bx-pager-link {
        display: block;
        width: 16px;
        height: 16px;
        text-indent: -9999px;
        background: $color-primary;
        border-radius: 4px;
        padding: 0;
        position: relative;
        margin-top: $block-padding * 1.5;
        margin-right: 6px;
        box-shadow: 0 0 3px -1px $color-black;

        &:hover {
            background: $color-secondary;
        }

        &.active {
            background: $color-secondary-light;

            &:hover {
                background: $color-secondary-dark;
            }
        }

        span {
            @extend .hidden;
        }
    }
    
}
